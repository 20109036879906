export const GuardarEnStorage = (clave, elemento) =>{
    //conseguir los elementos que haya en el localstorage
    let elementos = JSON.parse(localStorage.getItem(clave))
    //comprobar si es array
    if(Array.isArray(elementos)){
        elementos.push(elemento)
    }else{
        elementos = [elemento] 
    }
    //guardar en el local
    localStorage.setItem(clave,JSON.stringify(elementos));
    //devolver objeto guardado
    return elemento;
} 