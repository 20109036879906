import React, { useState } from 'react'
import { GuardarEnStorage } from '../helpers/GardarEnStorage'

export const Crear = ({setListadoState}) => {

    const tituloComponente = "Añadir Película"

    const [ peliState, setPeliState ] = useState({
        titulo:'',
        descripcion:''
    });

    const { titulo, descripcion } = peliState 

    const conseguirDatosForm = e => {
        e.preventDefault()
        let target = e.target;
        let titulo = target.titulo.value
        let descripcion = target.descripcion.value

        let peli = {
            id: new Date().getTime(),
            titulo,
            descripcion
        }
        //guardar estado
        setPeliState(peli)

        //actualizar estado listado
        setListadoState(elemtos=>{
            return [...elemtos, peli]
        })
        //guardar localmente
        GuardarEnStorage('pelis', peli);

        
    }

   

  return (
    <div className="add">
        <h3 className="title">{tituloComponente}</h3>
        <strong>
            {(titulo && descripcion) && "Se guardó: "+ titulo}
        </strong>
        <form onSubmit={conseguirDatosForm}>
            <input type="text" id="titulo" name="titulo" placeholder="Titulo" />
            <textarea id="description" name="descripcion" placeholder="Descripción"></textarea>
            <input type="submit" id="save" value="Guardar" />
        </form>
    </div>
  )
}