import logo from './logo.svg';
import './App.css';
import { Listado } from './components/Listado';
import { Buscador } from './components/Buscador';
import { Crear } from './components/Crear';
import { useState } from 'react';

function App() {
    const [listadoState, setListadoState] = useState([])
  return (
    <div className="layout">
        {/*Cabecera*/}  
        <header className="header">
            <div className="logo">
                <div className="play"></div>
            </div>
            
            <h1>Peliculas</h1>
        </header>
        {/*Barra de navegación*/}
        <nav className="nav">
            <ul>
                <li><a href="#">Inicio</a></li>
                <li><a href="#">Peliculas</a></li>
                <li><a href="#">Blog</a></li>
                <li><a href="#">Contacto</a></li>
            </ul>
        </nav>
        {/*Contenido principal*/}
        <div className='pelis'>
        <Listado listadoState={listadoState} setListadoState={setListadoState} />
        </div>
        {/*Barra lateral*/}
        <aside className="lateral">
            <Buscador listadoState={listadoState} setListadoState={setListadoState} />
            <Crear setListadoState={setListadoState} />
        </aside>
        {/*Pie de página*/}
        <footer className="footer">
            &copy; Juan Romero Resek - <a href="http://portafolio.portafolio.ar">Portafolio</a>
        </footer>
    </div>
  );
}

export default App;
