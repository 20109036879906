import React, { useState } from 'react'

export const Buscador = ({listadoState, setListadoState}) => {

  const [busqueda, setBusqueda] =useState('')
  const [noEncontrado, setNoEncontrado] =useState('false')

  const buscarPeli = (e) =>{
    //Crear estado y actualizarlo
    setBusqueda(e.target.value)

    //Listado completo de peliculas

    //Filtrar buscando coincidencia
    let pelis_encontradas = listadoState.filter(peli=>{
      return peli.titulo.toLowerCase().includes(busqueda.toLocaleLowerCase())
    })

    if(busqueda.length <= 1 || pelis_encontradas <= 0){
      pelis_encontradas = JSON.parse(localStorage.getItem('pelis'))
      setNoEncontrado(true)
    }else{
      setNoEncontrado(false)
    }
      
    //actualizar estado del listado
    setListadoState(pelis_encontradas)
  }
  console.log('busqueda: '+ busqueda)
  return (
    <div className="search">
        <h3 className="title">Buscar: {busqueda}</h3>
        {(noEncontrado === true && busqueda.length > 1) && ( 
          <span className='no-encontrado'>No hay coincidencias</span>
        )}
        <form>
            <input type="text" id="search_field" name='busqueda' autoComplete='off' value={busqueda} onChange={buscarPeli} />
        </form>
    </div>
    
  )

}
