import React from 'react'

export const Editar = ({peli, conseguirPeliculas, setEditar, setListadoState}) => {
    const titulo_componente = 'Editar pelicula'

    const guardarEdicion=(e, id)=>{
        e.preventDefault();

        let target = e.target;

        const pelis_almacenadas = conseguirPeliculas();

        const indice = pelis_almacenadas.findIndex(peli => peli.id === id)
        //crear objeto con indice, titulo, descripción
        let peli_actualiza = {
            id,
            titulo: target.titulo.value,
            descripcion: target.descripcion.value
        }
        //actualizar elemento
        pelis_almacenadas[indice] = peli_actualiza
        //guardar el nuevo array en el localstorage
        localStorage.setItem('pelis', JSON.stringify(pelis_almacenadas))
        
        //actualizar estados
        setListadoState(pelis_almacenadas);
        setEditar(0)
    }
       
    return (
    <div className='edit_form'>
        <h3 className='title'>{titulo_componente}</h3>
        <form onSubmit={e => guardarEdicion(e, peli.id)}>
            <input type='text' name='titulo' className='titulo_editado' defaultValue={peli.titulo}/>
            <textarea name='descripcion' className='descripcion_editada'defaultValue={peli.descripcion}/>
            <input type='submit' className='editar' value='Actualizar' />
        </form>
    </div>
  )
}
